/* 6.1 - FancyBox */
/*!fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fb-wrap, .fb-skin, .fb-outer, .fb-inner, .fb-image, .fb-wrap iframe, .fb-wrap object, .fb-nav, .fb-nav span, .fb-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fb-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fb-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.fb-opened {
  z-index: 10000; }

.fb-opened .fb-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fb-outer, .fb-inner {
  position: relative; }

.fb-inner {
  overflow: hidden; }

.fb-type-iframe .fb-inner {
  -webkit-overflow-scrolling: touch; }

.fb-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fb-image, .fb-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fb-image {
  max-width: 100%;
  max-height: 100%; }

#fb-loading, .fb-close, .fb-prev span, .fb-next span {
  background-image: url("//cdn.shopify.com/s/files/1/2487/3424/t/3/assets/fancybox_sprite.png?v=30059930562004970911510130795"); }

#fb-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  opacity: .8;
  cursor: pointer;
  z-index: 8060; }

#fb-loading div {
  width: 48px;
  height: 48px;
  background: url("//cdn.shopify.com/s/files/1/2487/3424/t/3/assets/fancybox_loading.gif?v=171509163315476599901510130795") center center no-repeat; }

.fb-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fb-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("//cdn.shopify.com/s/files/1/2487/3424/t/3/assets/blank.gif?0");
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040; }

.fb-prev {
  left: 0; }

.fb-next {
  right: 0; }

.fb-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fb-prev span {
  left: 10px; }

.fb-next span {
  right: 10px;
  text-align: right; }

.fb-nav:hover span {
  visibility: visible; }

.fb-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

.fb-lock {
  overflow: hidden !important;
  width: auto; }

.fb-lock body {
  overflow: hidden !important; }

.fb-lock-test {
  overflow-y: hidden !important; }

.fb-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: rgba(0, 0, 0, 0.8); }

.fb-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999; }

.fb-lock .fb-overlay {
  overflow: auto;
  overflow-y: scroll; }

.fb-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fb-opened .fb-title {
  visibility: visible; }

.fb-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fb-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fb-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fb-title-inside-wrap {
  padding-top: 10px; }

.fb-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

.fb-close:hover {
  opacity: 1; }

a.fb-next, a.fb-prev {
  font-size: 26px;
  opacity: 0.8;
  color: #000000; }

body.is-touch .fb-nav span {
  display: none; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fb-loading, .fb-close, .fb-prev span, .fb-next span {
    background-image: url(//cdn.shopify.com/s/files/1/2487/3424/t/3/assets/fancybox_sprite@2x.png?v=30059930562004970911510130795);
    background-size: 44px 152px; }

  #fb-loading div {
    background-image: url(//cdn.shopify.com/s/files/1/2487/3424/t/3/assets/fancybox_loading@2x.gif?v=171509163315476599901510130795);
    background-size: 24px 24px; } }
